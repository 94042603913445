import React, { useState, useRef, useMemo, useEffect, useImperativeHandle } from 'react';
import { MakePayMentComponents } from '../../../../features/index';
import intl from 'react-intl-universal';
import moment from 'moment';
import { Icon, message } from 'antd';
import { Get, Post } from 'utils/request';
import { OrderAction } from 'actions';
import { getOrderShowData } from '../../util';
import { useDispatch } from 'react-redux';
import { PaymentOrder, CheckDetails } from 'features/index';
import styles from './index.less'




function SyncTmallPayment({ status, types, userInfo, timezoneRegion, handleDeatilPayment }) {

    const [allPaymentData, setAllPaymentData] = useState([])
    const [detailData, setDetailData] = useState([])
    const [showPaymentOrder, setShowPaymentOrder] = useState(false)
    const [showSuccessPaymentOrder, setShowSuccess] = useState(false)
    const [nowType, setNowType] = useState('')
    const currentSyncRef = useRef([])
    let paymentOrderData = []
    let process = false
    const nowDeatilId = useRef('')

    const dispatch = useDispatch()

    useEffect(() => {
        getPaymentOrderProcess()
        return () => {
            process = true
        }
    }, [])

    useEffect(() => {
        window.EVENT.on(OrderAction.TMALL_PROCESS_START, async (params) => {
            process = false
            getPaymentOrderProcess()
        })
        return () => {
            window.EVENT.off(OrderAction.TMALL_PROCESS_START, true)
        }
    }, [])


    const sleep = (time) => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve()
            }, time);
        })
    }
    const getPaymentOrderProcess = async () => {
        while (!process) {
            const res = await Get('/supply/order/payments', {
                data: {
                    limit: 50,
                    follow: true,
                    platform: 4
                }
            });

            if (res.data?.payments?.length) {
                for (const paymentInfo of res.data.payments) {
                    const cachePayment = currentSyncRef.current?.find(
                        i => i.id === paymentInfo.id
                    )
                    paymentInfo.time = moment(new Date(paymentInfo?.created_at)).format('lll');
                    if (cachePayment) {
                        if (cachePayment.status < paymentInfo.status) {
                            Object.assign(cachePayment, paymentInfo);
                        }
                    } else {
                        currentSyncRef.current.push(paymentInfo);
                    }

                }
                currentSyncRef.current = [...currentSyncRef.current]
                setAllPaymentData([...currentSyncRef.current])
            } else {
                process = true
            }
            await sleep(5000)
        }
    }

    const showPaymentResult = useMemo(() => {
        return !!allPaymentData?.find(i => !i.isClose)
    }, [allPaymentData])

    const handleCheckDetail = (id, status) => {
        if ([1, 2].includes(status)) {
            setNowType('disabled')
        } else {
            setNowType('')
        }
        paymentOrderData = []
        let params = []
        let findPayment = allPaymentData.filter(item => {
            return item.id === id
        })
        findPayment[0]?.orders.forEach(item => {
            params.push(item.id)
        })
        dispatch({
            type: 'order/list',
            payload: {
                data: {
                    order_ids: params
                },
                callback: (d) => {
                    if (d.code === 2000) {
                        // 处理订单数据
                        const list = getOrderShowData(
                            d.data,
                            status, // 订单在那个tab下 pending 还是 awating order
                            types, // 用户付费等级
                            userInfo,
                            timezoneRegion // setting->report页设置的时区
                        );
                        if (status !== 2) {
                            setDetailData(list)
                            setShowPaymentOrder(true)
                        } else {
                            list.forEach(order => {
                                let newChildrens = []
                                order?.childrens?.forEach(child => {
                                    if (child?.sp.some(spItem => {
                                        return spItem.status === 4
                                    })) {
                                        newChildrens.push(child)
                                    }
                                })
                                order.childrens = newChildrens
                            })
                            setDetailData(list)
                            setShowSuccess(true)
                        }
                        nowDeatilId.current = id
                    }
                }
            }
        })
    }

    const hidePaymentOrder = async (type) => {
        setShowPaymentOrder(false)
        setShowSuccess(false)
        if (type === 'clickPayment') {
            handleClosePaymentTip(nowDeatilId.current)
            process = false
            getPaymentOrderProcess()
            handleDeatilPayment()
        }
    }

    const handleClosePaymentTip = async (id) => {
        const closeData = currentSyncRef.current?.find(i => i.id === id)
        if (closeData) {
            closeData.isClose = true
        }
        currentSyncRef.current = [...currentSyncRef.current]
        setAllPaymentData([...currentSyncRef.current])
        try {
            Post(`/supply/order/payments/${id}/unfollow`, { cancelMessage: true });
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{ marginTop: '10px' }}>
            {showPaymentOrder ? (
                <PaymentOrder
                    visible={showPaymentOrder}
                    supplyPlatform={4}
                    data={detailData}
                    status={status}
                    hidePaymentOrder={hidePaymentOrder}
                    nowType={nowType}
                />
            ) : null}
            {showSuccessPaymentOrder ? (
                <CheckDetails
                    visible={showSuccessPaymentOrder}
                    supplyPlatform={4}
                    data={detailData}
                    status={status}
                    hidePaymentOrder={hidePaymentOrder}
                    nowType={nowType}
                />
            ) : null}
            {
                showPaymentResult && status === 3 ? (
                    <div>
                        {
                            allPaymentData?.map(item => {
                                if (item.isClose) {
                                    return null
                                }
                                return (
                                    <MakePayMentComponents
                                        key={item.id}
                                        className={styles.payMentItem}
                                        checkDetailHandle={() => { handleCheckDetail(item.id, item.status) }}
                                        status={item.status}
                                        time={item.time}
                                        handleClose={() => { handleClosePaymentTip(item.id) }}
                                        paymentLink={item.redirect_url}
                                        error={item.err_reason}
                                    />
                                )
                            })
                        }
                    </div>
                ) : null
            }
        </div>
    )
}


export default React.memo(SyncTmallPayment)