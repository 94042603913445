import React, { Fragment } from 'react';
import { Icon, Popover, Input, Checkbox, Tooltip } from 'antd';

const { TextArea } = Input;

import className from 'classnames';
import intl from 'react-intl-universal';

import orderStyles from './Order.less';

import { notEditOrderNoteStatus } from './util.js';

import { CommonDataCollect } from 'utils/utils';

import './Order.css';

export default class OrderTitle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      noteStatus: false,
      note: '',
      noteCount:0
    };

    this.note = '';
  }

  // componentWillMount() {
  //   const {
  //     listData: {
  //       ext: { note = '' }
  //     }
  //   } = this.props;
  //   this.note = note;
  // }

  componentDidMount(){
    const {
      listData: {
        ext: { note = '' }
      }
    } = this.props;

    this.note = note;
    this.setState({
      note: note
    })
  }

  changeNote = e => {
    this.note = e.target.value;
    this.setState({
      note: e.target.value
    })
  };

  cancelOrder = (id) => {
    const {
      listData: {
        ext: { note = '' }
      }
    } = this.props;

    this.note = note;

    this.setState({
      noteStatus: false,
      note: note,
    })

    this.props.cancelOrder(id);
  }

  saveOrder = (data, item, note) => {
    if (note === 'note') {
      const { listData } = this.props
      const { storeId, detail: { id } } = listData
      CommonDataCollect({
        event_type: 'subscription_analysis',
        action: 'order_edit_note',
        store_id: storeId,
        sale_platform: 'shopify',
        sale_platform_product_id: id,
        action_value: this.note
      })
    }

    this.props.updataExt({
      value: this.note,
      type: 'note',
      oid: this.props.listData.id,
    });

    if(item){
      data.ext.flag = item.id
    }


    this.props.saveOrder(data);

    this.setState({
      noteStatus: false
    })
  }


  renderNote = () => {
    const {
      listData,
      listData: {
        ext: { address = {}, note = '', flags = 1 }
      },
      toggleCustomer,
      status,
      onChangeChecked,
      selectedRowsId,
      orderNo,
      flagsList
    } = this.props;

    const { noteCount } = this.state

    if(!!!this.state.noteStatus){
      return <>
          <p className={orderStyles.orderNote3} data-item="first-render" >
            {intl.get('order.order_title.note.title')}:
            <Tooltip title={note}>
              <div className={orderStyles.orderNoteDiv}>{note}</div>
              </Tooltip>
            <i class="material-icons" onClick={() => {this.setState({
            noteStatus: !!!this.state.noteStatus
          })}}>edit</i>
          </p>
      </>
    }

    return <>
      <Popover
        visible={this.state.noteStatus}
        onClick={() => {this.setState({
          noteStatus: !!!this.state.noteStatus
        })}}
        getPopupContainer={triggerNode => triggerNode.parentNode}
          content={
            <div className='noteCountBox'>
              <div className='noteTitle'>{intl.get('order.note_title_y')}</div>
              <div className='noteDesc'>
                {intl.get('order.note_desc_y')}
                <div className={this.state.note.length <= 1000 ? 'noteCount' : 'noteRedCount'}>{this.state.note.length} / 1000</div>
              </div>
              <TextArea  value={this.state.note} onChange={this.changeNote} />
              {this.state.note.length <= 1000 ? null : <div className='noteErrTip'>{intl.get('order.note_tip_y')}</div>}
              <div className='benDesf'>
                <div className={orderStyles.cancleOrder} onClick={() => this.cancelOrder(listData.id)}>{intl.get('order.confirm_modal_up.note_cancel')}</div>
                <div className={orderStyles.saveOrder} onClick={() => {
                  if(this.state.note.length>1000){
                    return
                  }
                  this.saveOrder(listData, '', 'note')
                }}>{intl.get('order.confirm_modal_up.node_save')}</div>
              </div>
            </div>
          }
          trigger="hover"
        >
        <p className={orderStyles.orderNote3}>
          {intl.get('order.order_title.note.title')}:
          <div className={orderStyles.orderNoteDiv}>{note}</div>
          <i class="material-icons">edit</i>
        </p>
      </Popover>
    </>
  }

  renderDom = (flagsList) => {
    const {
      listData,
    } = this.props;

    return <div className="flagImgListHover">
      {
        flagsList.map(item => {
          return <img width={24} key={item.id} onClick={() => this.saveOrder(listData, item)} src={item.img}></img>
        })
      }
    </div>
  }

  openShopifyUrl = () => {
    const { listData } = this.props
    const { storeId, detail: { id } } = listData
    CommonDataCollect({
      event_type: 'subscription_analysis',
      action: 'open_order_no',
      store_id: storeId,
      sale_platform: 'shopify',
      sale_platform_product_id: id
    })
  }

  render() {
    const {
      listData,
      listData: {
        ext: { address = {}, note = '', flags = 1 }
      },
      toggleCustomer,
      status,
      onChangeChecked,
      selectedRowsId,
      orderNo,
      flagsList
    } = this.props;

    const { noteCount } = this.state



    // flagsList 用来渲染数据

    return (
      <div className={orderStyles.orderTitle}>
        <div className="orderTitle_row">
          <span>
            <Checkbox
              disabled={listData.bStatus == 1 || listData.bStatus == 2}
              onChange={e => {
                onChangeChecked(e, listData);
              }}
              checked={selectedRowsId.includes(listData.id)}
            />
          </span>
          <span className={orderStyles.orderNo}>
            {intl.get('order.order_title.order_no')}:{' '}
            <a href={listData.shopifyUrl} target="_blank" onClick={this.openShopifyUrl}>
              {listData.orderNumber}
            </a>
          </span>
          {/* 渲染筛选标签 */}
          <div>
            <Tooltip
              overlayClassName="flagsTip"
              placement="right"
              title={this.renderDom(flagsList)}
              getPopupContainer={triggerNode => triggerNode.parentNode}
            >
              {
                flagsList.map(item => {
                  if(item.id == flags){
                    return <img width={24} key={item.id} src={item.img} />
                  }
                })
              }
            </Tooltip>
          </div>
        </div>

        <div className="orderTitle_colums">
          <span className={orderStyles.orderDate}>
            {intl.get('order.order_title.date')}: <b>{listData.date}</b>
          </span>
          <span className={orderStyles.orderCountry}>
            {intl.get('order.order_title.country')}: <b>{address.country}</b>
          </span>
          <span>
            {intl.get('order.order_title.income')}:&nbsp;
            <b>
              {listData.currency} {(listData.total * 1).toFixed(2)}
            </b>
          </span>
          <div className="editAddress" onClick={()=>{
            this.props.toggleCustomer()
            this.props.changeControlimg()
          }}>
            {intl.get('order.order_title.customer_detail')}
            {
              !this.props.controlimg?<img className='imgcontr' style={{transform: 'rotate(180deg)'}}
              src={require('../../assets/newImportList/icon_expand_more-24px.png')}
            ></img>:<img className='imgcontrOne' 
              src={require('../../assets/newImportList/icon_expand_more-24px.png')}
            ></img>
            }
          </div>
          {
            note ?
                <Fragment>
                  {!notEditOrderNoteStatus.includes(status) ? this.renderNote() : null}

                </Fragment>
             : !notEditOrderNoteStatus.includes(status) ? (
              <Popover
                visible={this.state.noteStatus}
                onClick={() => {this.setState({
                  noteStatus: !!!this.state.noteStatus
                })}}
                content={
                  <div className='noteCountBox'>
                    <div className='noteTitle'>{intl.get('order.note_title_y')}</div>
                    <div className='noteDesc'>
                    {intl.get('order.note_desc_y')}
                      <div className={this.state.note.length <= 1000 ? 'noteCount' : 'noteRedCount'}>{this.state.note.length} / 1000</div>
                    </div>
                    <TextArea  value={this.state.note} onChange={this.changeNote} />
                    {this.state.note.length <= 1000 ? null : <div className='noteErrTip'>{intl.get('order.note_tip_y')}</div>}
                    
                    <div className='benDesf'>
                      <div className={orderStyles.cancleOrder} onClick={() => this.cancelOrder(listData.id)}>CANCEL</div>
                      <div className={orderStyles.saveOrder} onClick={() => {
                        if(this.state.note.length>1000){
                          return
                        }
                        this.saveOrder(listData)
                      }}>SAVE</div>
                    </div>
                  </div>
                }
                trigger="click"
                getPopupContainer={triggerNode => triggerNode.parentNode}
              >
              <p className={orderStyles.orderNote}>
              {intl.get('order.order_title.note.title')}:
                <span className="addNote"><i style={{marginTop:8}} class="material-icons">add</i></span>
              </p>
            </Popover>
            ) : null}

        </div>
      </div>
    );
  }
}
