import { Modal, DatePicker, Button } from 'antd';
import moment from 'moment-timezone';
import React, { useState, useEffect, useMemo } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'dva';

import '../modalConfirm.css';

import styles from './SyncAliTimeRangeSelect.less';
import { getSyncTnTaskList } from '../../../services/order';

const { RangePicker } = DatePicker;


const getTimeStr = time => {
  const day = parseInt(time / 60 / 60 / 24);
  const hour = parseInt(time / 60 / 60 % 24);
  const minute = parseInt(time / 60 % 60);
  const seconds = parseInt(time % 60);
  return (
    (day ? `${day} days ` : '') +
    (hour ? `${hour} hours` : '') +
    (minute ? `${minute} minutes` : '') +
    (seconds ? `${seconds} seconds` : '')
  );
};

const RangeSelectContent = React.memo(({ onClose, onConfirm, timeZone }) => {
  const [taskCount, setTaskCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [min, max] = useMemo(() => {
    return [
      moment()
        .tz(timeZone)
        .subtract(29, 'days')
        .startOf('day')
        .unix(),
      moment()
        .tz(timeZone)
        .endOf('day')
        .unix()
    ];
  }, [timeZone]);
  // console.log(timeZone);
  const getDisabledDate = date => {
    const number = date.unix();
    return number > max || number < min;
  };

  const [range, setRange] = useState([
    moment()
      .tz(timeZone)
      .subtract(29, 'days')
      .startOf('day'),
    moment()
      .tz(timeZone)
      .endOf('day')
  ]);

  const handleRangeChange = rangeValues => {
    const [start, end] = rangeValues;
    setRange([start, end]);
  };

  const handleConfirm = () => {
    const [start, end] = range;
    if (typeof onConfirm === 'function') {
      onConfirm([start.unix(), end.unix()]);
    }
  };

  useEffect(() => {
    setRange([
      moment()
        .tz(timeZone)
        .subtract(29, 'days')
        .startOf('day'),
      moment()
        .tz(timeZone)
        .endOf('day')
    ]);
  }, [timeZone]);

  useEffect(() => {
    const getTaskCount = async () => {
      const [start, end] = range;
      setLoading(true);
      try {
        const res = await getSyncTnTaskList({
          data: {
            limit: 1,
            id: 0,
            start: start.unix(),
            end: end.unix()
          }
        });
        if (res.code === 2000) {
          // console.log(res);
          setTaskCount(res.data.count);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    getTaskCount();
  }, [range]);

  return (
    <div>
      <div className={styles.contentLine} style={{marginBottom:16}}>
        <RangePicker
          onChange={handleRangeChange}
          disabledDate={getDisabledDate}
          value={range}
        />
      </div>
      <div className={styles.contentLine} >
        {intl.get('order.sync_task_count')}: <b>{taskCount}</b>
      </div>
      <div className={styles.contentLine}>
        {intl.get('order.sync_except_time')}: {getTimeStr(taskCount * 3)}
      </div>
      <div className={styles.operation}>
        <Button data="dx" onClick={onClose}>
          {intl.get('order.order_product.shipping_dialog.cancel')}
        </Button>
        <Button data="dx" onClick={handleConfirm} disabled={!taskCount} type="primary">
          {intl.get('order.confirm.confirm')}
        </Button>
      </div>
    </div>
  );
});

const SyncAliTimeRangeSelect = React.memo(
  ({ visible, onConfirm, onClose, timeZone }) => {
    const cacluateTimezone = useMemo(() => {
      return timeZone || moment.tz.guess();
    }, [timeZone]);
    // console.log(cacluateTimezone, moment.tz.guess(), '-------------------');
    return (
      <Modal
        destroyOnClose
        footer={null}
        title={intl.get('order.select_sync_range')}
        visible={visible}
        onCancel={onClose}
        className="asyncModal"
      >
        <RangeSelectContent
          timeZone={cacluateTimezone}
          onClose={onClose}
          onConfirm={onConfirm}
        />
      </Modal>
    );
  }
);

export default connect(({ global }) => ({
  timeZone: global.timezoneRegion
}))(SyncAliTimeRangeSelect);
