import React, { useState } from 'react';
import { Modal, Button, Steps, Icon } from 'antd';
import guideData from '../Bargain/component/guideData';
import styles from './GuideModal.less';
import dsersRoboter from '../../assets/guide/dsers.png';
import facebook from '../../assets/guide/facebook.png';
import blog from '../../assets/guide/blog.png';

const { Step } = Steps;

const GuideModal = ({ status, guideStorage, closeModal, video = false }) => {

    const [guideNum, setGuideNum] = useState(0);

    const renderFooter = () => {
        if (guideNum === 0) {
            return (
                <Button className={styles.footerRightBtn} onClick={() => { setGuideNum(guideNum + 1) }}>NEXT</Button>
            )
        }

        if (guideNum + 1 < guideData[guideStorage]?.length) {
            return (
                <div>
                    <Button className={styles.footerLeftBtn} onClick={() => { setGuideNum(guideNum - 1) }}>BACK</Button>
                    <Button className={styles.footerRightBtn} onClick={() => { setGuideNum(guideNum + 1) }}>NEXT</Button>
                </div>
            )
        }

        if (guideNum + 1 === guideData[guideStorage]?.length) {
            return (
                video 
                ?
                <Button className={styles.footerLeftBtn} onClick={() => { setGuideNum(guideNum - 1) }}>BACK</Button>
                :
                <Button className={styles.footerRightBtn} onClick={closeModal}>OK</Button>
            )
        }
    }

    return (
        video ? 
            <div>
                <div className={styles.guideConVideo}>
                <img src={guideData[guideStorage] && guideData[guideStorage][guideNum]?.img} alt="" />
                <div className={styles.guideBottom}>
                    <img src={dsersRoboter} alt="" />
                    <div>
                        <div className={styles.title}>{guideData[guideStorage] &&  guideData[guideStorage][guideNum]?.title}</div>
                        <div className={styles.bottomText}>{guideData[guideStorage] && guideData[guideStorage][guideNum]?.text}</div>
                    </div>
                </div>
                <div className={styles.footerBot}>
                    <div className={styles.shareBot}>
                        <a href="https://www.blog.dserspro.com/"> <img src={blog} alt=""/>Blog</a>
                        <span>|</span>
                        <a href="https://www.youtube.com/channel/UCpiRdgB8VXvaht0AizSP9mA">
                            <Icon theme='filled' style={{fontSize: '24px', marginRight: '8px', color: '#757778' }} type="youtube" /> YouTube
                        </a>
                        <span>|</span>
                        <a href="https://www.facebook.com/DSersOfficial"> <img src={facebook} alt="" /> Facebook</a>
                        <label>|</label>
                        <div className={styles.back} onClick={() => {location.href= '/app/find_suppliers'}}>
                            <i class="material-icons">undo</i>Access to DSers
                        </div>
                    </div>
                    {renderFooter()}
                </div>
                    
                </div>
            </div>
        :
        <Modal
            title=""
            visible={status}
            width={743}
            style={{ position: 'relative' }}
            closable={false}
            footer={null}
        >
            <div className={styles.guideCon}>
                <img src={guideData[guideStorage] && guideData[guideStorage][guideNum]?.img} alt="" />
                <div className={styles.guideBottom}>
                    <img src={dsersRoboter} alt="" />
                    <div>
                        <div className={styles.title}>{guideData[guideStorage] && guideData[guideStorage][guideNum]?.title}</div>
                        <div className={styles.bottomText}>{guideData[guideStorage] && guideData[guideStorage][guideNum]?.text}</div>
                    </div>
                </div>
                {renderFooter()}
            </div>
            <Steps className={styles.guideSteps} direction="vertical" current={guideNum}>
                {
                    guideData[guideStorage]?.map((item) => {
                        return (
                            <Step />
                        )
                    })
                }
            </Steps>
        </Modal>
    )
}

export default GuideModal;
