import React from 'react';
import { Button, Alert } from 'antd';

import intl from 'react-intl-universal';

import cloneDeep from 'lodash/cloneDeep';

import OrderTitle from './OrderTitle.js';
import OrderItem from './OrderItem.js';
import OrderFooter from './OrderFooter.js';
import EditAddress from './Order/EditAddress.js';

import { CommonDataCollect } from '../../utils/utils'
import { PromptForWholeOrderComponent } from 'features'
import {
  notEditOrderStatus,
  getHasShopifyFulfilled,
  getHasTrackingFulfilled,
  getHasManuallyFulfilled,
  getHasSourceOther,
  getHasSourceAE
} from './util.js';

import { getChromeExtOptionsPage } from 'utils/fetchFromExtension';

import orderStyles from './Order.less';

import { routerRedux } from 'dva/router';
import { Post } from 'utils/request';
import isEqual from 'lodash/isEqual';
const YG_COUNTRY = [
  {
    key:'British Indian Ocean Territory',
    name:'British Indian Ocean Territory'
  },
  {
    key:'Isle Of Man',
    name:'Isle of Man'
  },
  {
    key:'Pitcairn',
    name:'Pitcairn Islands'
  },
  {
    key:'Saint Helena',
    name:'St Helena, Ascension, Tristan da Cunha'
  },
  {
    key:'Tristan da Cunha',
    name:'St Helena, Ascension, Tristan da Cunha'
  }
]

export default class OrderOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditaddress: false,
      isPengding: false,
      isShow: true,
      asyncState: false,
      asyncProId: '',
      asyncSku: '',
      isLegalOrderNo: true,
      isShowInput: false,
      holdOrderError:true,
      showSaveBtn:false,
      singleErrorTips:false,
      ids:{},
      errTip:{},
      controlimg:true,

      // 保存按钮
      addSave:false
    };

    this.orderOneRef = React.createRef(null);
  }

  componentDidMount(){
    
  }

  componentWillMount() {
    // 不是失败单就return
    if (this.props.status != 7) {
      return;
    }

    this.Ab = cloneDeep(this.props.record.ext)

    const { code, msg = '' } = this.props.record.ext.err || {};
    let flag = false;
    const msgHasErr = this.msgHasErr(msg);

    // 6 10 11 说明收货人地址错误  msgHasErr表示具体哪个字段错了
    if ([6, 10, 11].includes(code) || msgHasErr) {
      flag = true;
    }

    let key = this.props.record.id

    this.setState({
      showEditaddress: flag,
      key:true
    });
  }

  componentWillReceiveProps(nextProps) {
    const {
      record: { showSave }
    } = nextProps;
    if(showSave != this.state.showSaveBtn){
      this.setState({
        showSaveBtn:showSave,
        showEditaddress: showSave && this.state.showEditaddress
      });
    }
    if(!isEqual(nextProps.errTip,this.state.errTip)){
      this.setState({
        errTip:nextProps.errTip
      })
    }
  }
  componentDidUpdate(prevProps) {
    const {
      record,
      originalOrderDataFromRedux,
      callLogServiceForBlankOrder
    } = this.props;

    // 由于上游数据实施不规范，这里无法识别 list 更新，因此使用原始 redux store 数据鉴定
    // 取消缓存
    if (
      originalOrderDataFromRedux !== prevProps.originalOrderDataFromRedux &&
      originalOrderDataFromRedux?.Orders?.orders &&
      originalOrderDataFromRedux.Orders.orders.length > 0
    ) {
      setTimeout(() => {
        if (this.orderOneRef.current) {
          const domItems = this.orderOneRef.current.querySelectorAll(
            'div[data-label="order-item-box"]'
          );
          // 什么都没渲染时就是空白单
          if (domItems && domItems.length === 0) {
            console.log('❌ 空白单：', record);
            const lineItems =
              record.ext &&
              record.ext.lineitems &&
              record.ext.lineitems.map(i => {
                const res = {
                  order_id: record.id,
                  line_item_id: i.lineitem_id,
                  line_item_status: i.status,
                  sp_status: i.sp && i.sp.map(spItem => spItem.status).join(),
                  closed_at: record.detail && record.detail.closed_at
                };

                return res;
              });

            if (lineItems && lineItems.length > 0) {
              callLogServiceForBlankOrder({
                orders: lineItems
              });
            }
          }

          // 【增加逻辑判断 item如果存在onhold 則按钮不允许点击】
          const orderHoldTip = this.orderOneRef.current.querySelectorAll(
            'div[data-label="productTipHold"]'
          );
          if (orderHoldTip?.length) {
            this.setState({holdOrderError:false})
          }
        }
      });
    }
  }

  msgHasErr = msg => {
    const arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8'];
    let flag = false;

    arr.forEach(item => {
      if (msg.includes(item)) {
        flag = true;
      }
    });

    return flag;
  };

  // 输入框控制保存按钮
  changeSave=()=>{
    this.setState({addSave:true})
  }

  changeAsync = (sku, productId, lineItemId) => {
    this.setState({
      asyncState: true,
      asyncSku: sku,
      asyncProId: productId,
      lineItemId
    });
  };

  changeOrderNoState = () => {
    this.setState({
      isLegalOrderNo: false
    });
  };

  // 显示隐藏收货人信息
  toggleCustomer = () => {
    const {
      status,
      updataExt,
      record,
      record: {
        id,
        ext: { address = {} }
      },
    } = this.props;

    const { showEditaddress } = this.state;

    if (!showEditaddress) {
      const { storeId, detail: { id } } = record
      CommonDataCollect({
        event_type: 'subscription_analysis',
        action: 'order_open_customer_detail',
        store_id: storeId,
        sale_platform: 'shopify',
        sale_platform_product_id: id
      })
    }

    if (!showEditaddress && !notEditOrderStatus.includes(status)) {
      updataExt({
        type: 'address',
        address,
        oid: id
      });
    }
    
   this.setState({
      showEditaddress: !showEditaddress
    });
  };

  // 获取收货人信息错误提示
  getAddressErrorTip = () => {
    const {
      status,
      record: {
        ext: {
          address: { country_code }
        }
      }
    } = this.props;
    let tip = null;

    // 不是失败单不提示
    if (status != 7) {
      return tip;
    }

    // 不能运往中国提示
    if (country_code === 'CN') {
      tip = (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          {intl.get('order.err_shipping_to_china')}
        </p>
      );
    }

    // 不能运往俄罗斯提示
    if (['RU'].includes(country_code)) {
      tip = (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          {intl.get('order.err_shipping_to_RU')}
        </p>
      );
    }

    return tip;
  };

  /**
   * 除了此提示其余提示不展示
   * @param {*} sta 当前tab
   * @returns 
   */
  singleErrorTip = (sta) =>{
    const {
      record: { ext },
      status,
      dispatch
    } = this.props;
    // 印度订单特殊展示
     if(ext.address.country_code == 'IN' && sta == '7'){
      !this.state.singleErrorTips && this.setState({singleErrorTips:true})
      return (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          {intl.getHTML('order.ae_tip_IN_error')}
        </p>
      );
     }else{
       this.state.singleErrorTips &&  this.setState({singleErrorTips:false})
     }
  };
  getExtPlaceErrorTip = (msg = '') => {
    // console.log(msg);
    if(msg.includes('type: ACCOUNT_ERROR')) {
      // error code 12
      if(msg.includes('msg: ALIEXPRESS_ACCOUNT_LOGGED_OUT')) {
        return intl.get('order.place_via_extension.error_tip_ae_logout');
      }
    }
    // error code 12
    if(msg.includes('type: TIMEOUT_ERROR')) {
      return intl.get('order.place_via_extension.error_tip_action_timeout');
    }
    // error code 12
    if(msg.includes('msg: GET_AE_CSRF_TOKEN_ERROR')) {
      return intl.get('order.place_via_extension.error_tip_action_timeout');
    }
    // error code 12
    if(msg.includes('type: CART_ERROR')) {
      return intl.get('order.place_via_extension.error_tip_cart_error');
    }
    if(msg.includes('type: OTHER_ERROR')) {
      // error code 11
      if(msg.includes('msg: ORDER_PAGE_UNAVALIABLE')) {
        return intl.get('order.place_via_extension.error_tip_unknown');
      }
      // error code 12
      if(msg.includes('msg: USER_CLOSE_ORDER_PAGE')) {
        return intl.get('order.place_via_extension.error_tip_manually_close_1');
      }
      // error code 11
      if(msg.includes('msg: USER_ABORT_TASKS')) {
        return intl.get('order.place_via_extension.error_tip_manually_abort');
      }
      return null;
    }

    // error code 12
    if(msg.includes('type: UNKNOWN_ERROR')) {

      if(msg.includes('msg: UNKNOWN_ERROR')) {
        return intl.get('order.place_via_extension.error_tip_manually_close_2');
      }
      if(msg.includes('msg: UNEXCEPT_NO_SP_TO_PLACE')) {
        return intl.get('order.place_via_extension.error_tip_unknown');
      }
    }
    return null;
  }

  getAeCodeErrorTip = () => {
    const {
      record: { ext },
      status,
      dispatch
    } = this.props;
    if (ext?.multiple_platform_error?.tmall?.code === 11) {
      // 先判断是否是插件下单特有错误
      if((ext?.multiple_platform_error?.tmall?.msg || '').includes('type:')) {
        const extPlaceMsg = this.getExtPlaceErrorTip(ext?.multiple_platform_error?.tmall?.msg);
        if(extPlaceMsg) {
          return (
            <p className={orderStyles.productTip}>
              <i class="material-icons notranslate">error</i>{' '}
              {extPlaceMsg}
            </p>
          );
        }
      }
      if (/P-CHECKOUT-TAX-NEED-ADDRESS-EXCEPTION/g.test(ext?.multiple_platform_error?.tmall?.msg)) {
        return (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>{' '}
            {intl.getHTML('order.ae_code_11_error')}
          </p>
        );
      } else if (/A004_CANNOT_USER_COUPON/g.test(ext?.multiple_platform_error?.tmall?.msg)) {
        return (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>{' '}
            {intl.getHTML('order.ae_code_11_error_btn')}
          </p>
        );
      } else if (/A003_SUSPICIOUS_BUYER/g.test(ext?.multiple_platform_error?.tmall?.msg)) {
        return (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>{' '}
            {intl.getHTML('order.ae_code_11_error3')}
          </p>
        );
      }else if(/BUY_LIMIT_RESOURCE_INSUFFICIENT/g.test(ext?.multiple_platform_error?.tmall?.msg)){
        return (
          <p className={orderStyles.productTip}>
            {intl.get('order.err_limit_blur')}
          </p>
        );
      }else{
        return (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>{' '}
            {intl.getHTML('order.ae_code_11_error4')}
          </p>
        );
      }
    }
    // 插件下单预期之外的错误 - 包括网络异常 ae页面变更 添加购物车失败等
    if(
      ext?.multiple_platform_error?.tmall?.code === 12 &&
      /type:/g.test(ext?.multiple_platform_error?.tmall?.msg)
    ) {
      // 先判断是否是插件下单特有错误
      const extPlaceMsg = this.getExtPlaceErrorTip(ext?.multiple_platform_error?.tmall?.msg || '');
      if(extPlaceMsg) {
        return (
          <p className={orderStyles.productTip}>
            <i class="material-icons notranslate">error</i>{' '}
            {extPlaceMsg}
            {/* {intl.getHTML('order.place_via_extension.failed_order_exsits')} */}
          </p>
        );
      }

    }
    // 插件下单ae place confirm页面 没有支持的支付方式
    if(ext?.multiple_platform_error?.tmall?.code === 23) {
      const extSettingPage = getChromeExtOptionsPage();
      return (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          <p>
            There is no payment method available for this order on the AliExpress order confirmation page. You can{' '}
            <a
              href={extSettingPage}
              target="_blank"
              // rel="noopener noreferrer"
            >
              set the behavior when this error occurs in dsers Chrome extension
            </a>
            , or you can place an order using the API
          </p>
        </p>
      );
    }

    if (ext?.multiple_platform_error?.tmall?.code === 22) {
      return (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          {intl.get('order.place_order_timeout')}
        </p>
      );
    }

    // if(ext?.multiple_platform_error?.tmall?.code === 21){
    //   return (
    //     <p className={orderStyles.productTip}>
    //       <i class="material-icons notranslate">error</i>{' '}
    //       {intl.get('order.ae_code_21_error')}
    //     </p>
    //   );
    // }

  };
  getTmallCodeErrorTip = () => {
    const {
      record: { ext },
      status,
      dispatch
    } = this.props;
    if (ext?.multiple_platform_error?.tmall?.code != 10000) {
      return
    }
    if (["B-00903-10-15-010","P-02306-11-16-011","B-00504-01-16-001","F-10000-00-16-099","P-01415-10-16-042",
      "F-10000-01-16-000","P-01415-00-16-102","P-02309-11-10-002", "F-10002-01-16-019","F-10002-01-99-008","F-10000-00-16-100",
      "F-10000-00-16-101"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code1')}
    </p> 
    }
    if (["B-50018-00-01-001"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.err_extra_address')}
    </p> 
    }
    if (["B-00118-00-15-001"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code3')}
    </p> 
    }
    if (["F-10006-03-16-004"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code4')}
    </p> 
    }
    if (["P-02306-11-16-001"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code5')}
    </p> 
    }
    if (["F-00103-00-16-113"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code6')}
    </p> 
    }
    if (["P-02114-10-16-002","F-10002-03-16-013","F-10003-11-16-002"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code7')}
    </p> 
    }
    if (["F-10012-01-16-001","F-10012-01-16-016"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code8')}
    </p> 
    }
    if (["P-10000-11-16-014","F-10001-01-16-001","F-10001-01-16-011","F-10003-11-16-006"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code9')}
    </p> 
    }
    if (["F-10003-11-16-001","F-10003-11-16-003"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code10')}
    </p> 
    }
    if (["F-10003-11-16-007"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code11')}
    </p> 
    }
    if (["P-10000-11-17-019","P-10000-11-17-018"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code12')}
    </p> 
    }

    if (["F-10004-03-16-004"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code13')}
    </p> 
    }

    if (["F-10001-01-16-023"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.err_not_available').replace("AliExpress","Tmall")}
    </p> 
    }

    if (["F-10005-11-10-003","F-10005-11-10-004","F-10005-11-10-005","F-10005-11-10-002"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code15')}
    </p> 
    }
    
    if (["F-10002-11-10-001","F-10002-11-10-002","F-10002-11-10-022","F-10002-11-10-039"].includes(ext?.multiple_platform_error?.tmall?.msg)) {
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_error_code16')}
    </p> 
    }
    return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.err_varients_change')}
    </p> 
  }

  getOrderAllTip = () => {
    if (this.props.status !== 2) {
         return
    }
    const { errTip } = this.state;
    if (!Array.isArray(errTip?.error) || !errTip?.error.length) {
      return null
    }
    return (
      <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          <div>
            {errTip.error.map((item, index) => {
              let itemAddDot = `${index + 1}.${item}`
              return (
                index == 0 && errTip.error.length == 1 ?
                  
                <div
                dangerouslySetInnerHTML={{ __html: item }}
                  /> : 
                <div
                  dangerouslySetInnerHTML={{ __html: itemAddDot }}
                  /> 
                )
            })}
          </div>
        </p>
      );
  } 
    
  // sku变更后的展示
  getSkuError = status => {
    if (![2, 7].includes(status)) {
      return null;
    }
    const {
      record: { ext }
    } = this.props;
    if (ext?.multiple_platform_error?.tmall?.code === 21){
      return <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.getHTML('order.tmall_sku3_error')}
      </p>
    }
    if(ext?.multiple_platform_error?.tmall?.code == 26){
      return  <p className={orderStyles.productTip}>
      <i class="material-icons notranslate">error</i>
      {intl.get('order.tmall_sku1_error')}
    </p>
    }
    return null;
  }

  getOrderChangeSku = status => {
    if (![7].includes(status)) {
      return null;
    }
    const {
      record: { ext }
    } = this.props;
    if (ext?.multiple_platform_error?.tmall?.code === 26) {
      return <p className={orderStyles.productTip}>
               <i class="material-icons notranslate">error</i>
                The price of the item(s) in your order has changed, please reorder
             </p>
    }
    return null;
  }
  
  //新增的code提示
  getExtraAddressErrorTip = () => {
    const {
      record: { ext, childrens },
      status
    } = this.props;

    let flag;
    if(status == 6){
      childrens.map(childItem => {
        if(childItem.AECanceled[0] == 1){
          flag = true
        }else{
          flag = false
        }
      })
      if(flag && ext?.multiple_platform_error?.tmall?.code == 24){
        return <p className={orderStyles.productTip}>
        <i class="material-icons notranslate">error</i>
        {intl.get('order.order_code_24_error')}
      </p>
      }
    }

    if([2,7].includes(status)){
      //必须是ae cancel 才显示

      if(ext?.multiple_platform_error?.tmall?.code == 24){
        return <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>
          {intl.get('order.order_code_24_error')}
        </p>
      }
    }

    // 不是失败单不提示
    if (status != 7) {
      return null;
    }

    // 下单超时失败时不提示地址错误
    if (ext?.multiple_platform_error?.tmall?.msg === 'DeadlineExceeded' && ext?.multiple_platform_error?.tmall?.code === 22) {
      return null;
    }

    if (
      (ext?.multiple_platform_error?.tmall?.msg && /DSERS_CHECK_ADDRESS_ERROR/g.test(ext?.multiple_platform_error?.tmall?.msg)) ||
      [6, 10].includes(ext?.multiple_platform_error?.tmall?.code)
    ) {
      return (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          {intl.get('order.err_extra_address')}
         { ext?.address?.country_code == 'UA' ? 
         intl.get('order.err_add_address_ukraine') : null
        }
          
          {/* {console.log('ext',ext,YG_COUNTRY.includes(this.Ab.address.country))} */}
          {/* 判断是不是这个国家 */}
          {
            YG_COUNTRY.map(item => {
              if(item.key == this.Ab.address.country){
                return <> {intl.get('order.err_address_yg',{
                  country:this.Ab.address.country_code,
                  countrys:item.name
                })} </>
              }
            })
          }


        </p>
      );
    }
    if(ext?.multiple_platform_error?.tmall?.code == 14 && /DELIVERY_METHOD_NOT_EXIST/g.test(ext?.multiple_platform_error?.tmall?.msg) ){
      return <p className={orderStyles.productTip}>
        <i class="material-icons notranslate">error</i>
        {intl.get('order.ae_code_14_error')}
      </p>
    }
    return null;
  };

  //拆单错误提示
  getSplitOrder = () => {
    const {
      record,
      status
    } = this.props;


    if (record.bStatus == 4) {
      return (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          {record.bMsg}
        </p>
      );
    }
  }

  orderPartialCompletionPrompt = () => {
    const {
      record: { ext },
      status
    } = this.props;
    const { lineitems = [] } = ext;

    const order =
      lineitems.length > 0 &&
      lineitems.filter(
        item =>
          item.sp && item.sp[0] && item.sp[0].status && item.sp[0].status === 11
      );
    if (status === 9 && order.length !== 0) {
      return (
        <p className={orderStyles.productTip}>
          <i class="material-icons notranslate">error</i>{' '}
          {intl.get('order.partial_completion_of_order')}
        </p>
      );
    }

    return null;
  };

  /**
   * 订单点击fulfill重试
   *
   * @param {*} record  当前订单信息
   * @memberof OrderOne
   */
  fulfillReshChange = async record => {
    const data = {
      OrderId: record.id
    };

    if (record.fulfillStatus === 2) {
      data['StoreId'] = record.storeId;
    }

    if (record.fulfillStatus === 3) {
      data['RefreshOrder'] = true;

      this.setState({
        isPengding: true
      });

      setTimeout(() => {
        this.setState(
          {
            isShow: false
          },
          () => {
            this.props.refreshFulfill();
          }
        );
      }, 5000);
    }

    try {
      let response = await Post('/fulfillment/retry', {
        data: data
      });
      if (response.code == 2010) {
        if (record.fulfillStatus === 2) {
          this.props.dispatch(routerRedux.push('/settings?tabChangeSta=3'));
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  bindRef(ref) {
    this.child = ref;
  }

  handleSaveNotice = (record) => {
    this.props.saveOrder(
      record.id,
      this.state.asyncState,
      this.state.isLegalOrderNo,
      this.state.asyncSku,
      this.state.asyncProId,
      this.state.lineItemId,
      record
    );
  }

  upDataExt = (record) => {
    this.props.updataExt(record)
    if(record.type == 'msg'){
      setTimeout(()=> {
        this.props.saveOrder(
          record.id,
          this.state.asyncState,
          this.state.isLegalOrderNo,
          this.state.asyncSku,
          this.state.asyncProId,
          this.state.lineItemId
        );
      },300)
    }
  }

  close_banner = () => {
    const { record } = this.props
    localStorage.setItem(record.id,true)
  }
   // 按钮上下箭头
   changeControlimg=()=>{
    this.setState({controlimg:!this.state.controlimg})
  }
  render() {
    const {
      record,
      status,
      updataExt,
      toggleBind,
      cancelOrder,
      saveOrder,
      placeOrder,
      markAsFulfilled,
      showMapping,
      renderIndex,
      removedVariants,
      fulfilledBy,
      activeBannerItems,
      onBannerClick,
      sourceBy,
      refreshFulfill,
      onChangeChecked,
      selectedRowsId,
      errTip,
      onePayment
    } = this.props;

    const { showEditaddress,singleErrorTips } = this.state;

    return (
      <div
        className={orderStyles.orderBox}
        data-category="order-item"
        ref={this.orderOneRef}
      >
        {/* 隐藏域，为了 guide */}
        <button
          onClick={() => {
            this.setState({
              showEditaddress: false
            });
            saveOrder(
              record.id,
              this.state.asyncState,
              this.state.isLegalOrderNo,
              this.state.asyncSku,
              this.state.asyncProId,
              this.state.lineItemId
            );
          }}
          style={{ display: 'none' }}
          type="button"
          aria-label="save order item"
          data-label="save-order-item-hidden-button"
        />
        {record.showSave ? (
          <div
            className={orderStyles.orderToolLine}
            data-label="save-order-item-operation-header"
          >
            <Button
              className={orderStyles.orderToolCancel}
              data='bk'
              onClick={() => {

                this.setState({
                  showEditaddress:false,
                  isShowInput: false,
                  addSave:false,
                  controlimg:!this.state.controlimg
                }, () => {

                  cancelOrder(record.id, this.state.showEditaddress);

                  return
                });

                // cancelOrder(record.id);
              }}
            >
              {intl.get('public.cancel')}
            </Button>
            {/* 保存按钮 */}
            {/* {
              this.props.changetab==2? <Button
              className={orderStyles.orderToolSave}
              data="dx"
              style={this.state.addSave===false?{"display":"none"}:{ "display": "inline-block"}}
              onClick={() => {
                this.setState({
                  showEditaddress: false,
                  addSave:false,
                  controlimg:!this.state.controlimg
                });
                saveOrder(
                  record.id,
                  this.state.asyncState,
                  this.state.isLegalOrderNo,
                  this.state.asyncSku,
                  this.state.asyncProId,
                  this.state.lineItemId
                  
                );
              }}
            >
            {intl.get('public.save')}
            </Button>:this.props.changetab==6?<Button
              className={orderStyles.orderToolSave}
              data="dx"
              style={this.state.addSave===false?{"display":"none"}:{ "display": "inline-block"}}
              onClick={() => {
                this.setState({
                  showEditaddress: false,
                  addSave:false,
                  controlimg:!this.state.controlimg
                });
                saveOrder(
                  record.id,
                  this.state.asyncState,
                  this.state.isLegalOrderNo,
                  this.state.asyncSku,
                  this.state.asyncProId,
                  this.state.lineItemId
                  
                );
              }}
            >
            {intl.get('public.save')}
            </Button>:<Button
              className={orderStyles.orderToolSave}
              data="dx"
              // style={this.state.addSave===false?{"display":"none"}:{ "display": "inline-block"}}
              onClick={() => {
                this.setState({
                  showEditaddress: false,
                  // addSave:false,
                  controlimg:!this.state.controlimg
                });
                saveOrder(
                  record.id,
                  this.state.asyncState,
                  this.state.isLegalOrderNo,
                  this.state.asyncSku,
                  this.state.asyncProId,
                  this.state.lineItemId
                  
                );
              }}
            >
            {intl.get('public.save')}
            </Button>
            } */}

              <Button
                className={orderStyles.orderToolSave}
                data="dx"
                // style={this.state.addSave===false?{"display":"none"}:{ "display": "inline-block"}}
                onClick={() => {
                  this.setState({
                    showEditaddress: false,
                    addSave:false,
                    controlimg:!this.state.controlimg
                  });
                  saveOrder(
                    record.id,
                    this.state.asyncState,
                    this.state.isLegalOrderNo,
                    this.state.asyncSku,
                    this.state.asyncProId,
                    this.state.lineItemId
                  
                  );
                }}
              >
              {intl.get('public.save')}
            </Button>
          </div>
        ) : null}
        <div style={{ padding: '0 12px' }}>
        <PromptForWholeOrderComponent data={record} tabStatus={status} type={4} />
        </div>
        {/* {this.getSkuError(status)} */}
        {this.getOrderChangeSku(status)}
        {this.getOrderAllTip()} 
        {status == '7' ? this.getTmallCodeErrorTip() : null}
        <OrderTitle
          flagsList={this.props.flagsList}
          listData={record}
          toggleCustomer={this.toggleCustomer}
          updataExt={updataExt}
          status={status}
          onChangeChecked={onChangeChecked}
          selectedRowsId={selectedRowsId}
          cancelOrder={cancelOrder}
          saveOrder={this.handleSaveNotice}
          controlimg={this.state.controlimg}
          changeControlimg={this.changeControlimg}
        />
        {/* 临时await order 通知 */}
        {/* {
          localStorage.getItem(record.id) ? null : 
          <div className='orderTransit iut'>
            <div className='await'>
              <img src="https://img.dsers.com/shopify/order/info.png"></img>
            </div>
            <div>
              Due to AliExpress system issue, this order may have been repeated on AliExpress. Please check on AliExpress and contact the suppliers.
            </div>
            <div className='close' onClick={this.close_banner}>
              <img src="https://img.dsers.com/shopify/order/close.png"></img>
            </div>
          </div>
        } */}
        {/* 订单fulfill */}
        {status === 9 &&
        (record.fulfillStatus === 2 || record.fulfillStatus === 3) ? (
          this.state.isShow ? (
            <div className={orderStyles.refesh}>
              {this.state.isPengding ? (
                <div className={orderStyles.fulfillBox}>
                  {intl.getHTML('order.order_pending')}
                </div>
              ) : (
                <div className={orderStyles.fulfillBox}>
                  {record.fulfillStatus === 2
                    ? intl.getHTML('order.token_authorize_info')
                    : intl.getHTML('order.order_fulfill')}
                  <a
                    onClick={() => {
                      this.fulfillReshChange(record);
                    }}
                  >
                    {record.fulfillStatus === 2
                      ? intl.getHTML('order.token_authorize_info_btn')
                      : intl.getHTML('order.order_pending_btn')}
                  </a>
                </div>
              )}
            </div>
          ) : null
        ) : null}
        {showEditaddress ? (
          <div className={orderStyles.buyerAddress}>
            <EditAddress
              onRef={(ref)=>{ this.child = ref}}
              listData={record}
              status={status}
              updataExt={updataExt}
              renderIndex={renderIndex}
              changeSave={this.changeSave}

            />
          </div>
        ) : null}
        {this.singleErrorTip(status)}
        {
        !singleErrorTips &&
        <>
        {this.getAddressErrorTip()}
        {this.getExtraAddressErrorTip()}
        {this.getSplitOrder()}
        {status == '7' ? this.getAeCodeErrorTip() : null}
        {this.orderPartialCompletionPrompt()}
        </>
        }
        {record.childrens.map((item, index) => {
          const isFulfilledTab = status == 5;
          
          //只展示天猫商品
          if (item.platform_type != 4) {
            return null;
          }

          // 加黑商品不显示
          if (item.is_black_list === 2) {
            return null;
          }

          // 是不是shopify fulfilled
          if (
            isFulfilledTab &&
            fulfilledBy === 1 &&
            getHasShopifyFulfilled(item, record) === false
          ) {
            return null;
          }

          // 正常下单fulfilled
          if (
            isFulfilledTab &&
            fulfilledBy === 2 &&
            getHasTrackingFulfilled(item, record) === false
          ) {
            return null;
          }

          // dsers手动fulfilled
          if (
            isFulfilledTab &&
            fulfilledBy === 3 &&
            getHasManuallyFulfilled(item, record) === false
          ) {
            return null;
          }

          // other单
          if (
            isFulfilledTab &&
            sourceBy === 1 &&
            getHasSourceOther(item, record) === false
          ) {
            return null;
          }

          // ae单
          if (
            isFulfilledTab &&
            sourceBy === 2 &&
            getHasSourceAE(item, record) === false
          ) {
            return null;
          }
          // 一条子订单
          return (
            <OrderItem
              listData={record}
              onRef={this.bindRef.bind(this)}
              errTipItem={errTip?.childrens?.[item.id]}
              child={item}
              singleErrTips={singleErrorTips}
              index={index}
              key={item.id}
              renderIndex={renderIndex}
              status={status}
              updataExt={this.upDataExt}
              toggleBind={toggleBind}
              markAsFulfilled={markAsFulfilled}
              isShowInput={this.state.isShowInput}
              showMapping={showMapping}
              onBannerClick={onBannerClick}
              activeBannerItems={activeBannerItems}
              removedVariants={removedVariants}
              changeAsync={this.changeAsync}
              changeOrderNoState={this.changeOrderNoState}
            />
          );
        })}
        <OrderFooter
          listData={record}
          status={status}
          placeOrder={placeOrder}
          isClickOrder = {errTip.isOrder && this.state.holdOrderError}
          onePayment={onePayment}
        />
      </div>
    );
  }
}
