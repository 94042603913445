import React from 'react';
import { Icon, Tooltip, Dropdown, Menu, Modal, Input, Button } from 'antd';

import ActionsLimeTime from './ActionsLimeTime';
import { connect } from 'dva';
import className from 'classnames';
import intl from 'react-intl-universal';
import reactGaEvent from '../../../utils/GAEvent';

import orderStyles from '../Order.less';
import '../Order.css';

import { getLanguage, CommonDataCollect } from '../../../utils/utils';

import { webCustomCollect } from 'utils/utils'

const { TextArea } = Input;
@connect(({ global,user }) => {
  return {
    TMCurrentMess: global.TmallBoard,
    importFlag:user,
  };
})
export default class Actions extends React.PureComponent {
  messageMaxLength = 70;

  constructor(props) {
    super(props);
    this.state = {
      showOrderLeaving: false,
      textAreaVal: '',
      generalBtnDisabled: true
    };
  }

  componentDidMount() {
    const {
      useSp,
      listData: {
        detail: { note = '' }
      },
      child
    } = this.props;

    const settingOrderData = JSON.parse(localStorage.getItem('settingOrderData')) || {};
    let msg = '';

    if (!useSp.msg) {
      // 用户开启 才发送留言
      msg =
        note && settingOrderData.SyncShopifyNotesStatus
          ? note
          : '';
      msg = settingOrderData.desc ? `${msg} ${settingOrderData.desc}` : msg;
    }

    this.defaultMsg = useSp.msg || msg || '';

    this.setState({
      textAreaVal: useSp.msg || msg || ''
    });
  }

  defaultMsg = '';

  // 隐藏order留言
  hideOrderLeaving = (type) => {
    if(type == 'close'){
      const {
        useSp,
        listData: {
          detail: { note = '' }
        },
        child
      } = this.props;

      const settingOrderData = JSON.parse(localStorage.getItem('settingOrderData')) || {};
      let msg = '';

      if (!useSp.msg) {
        // 用户开启 才发送留言
        msg =
          note && settingOrderData.SyncShopifyNotesStatus
            ? note
            : '';
        msg = settingOrderData.desc ? `${msg} ${settingOrderData.desc}` : msg;
      }

      this.setState({
        showOrderLeaving:false,
        textAreaVal: useSp.msg || msg || ''
      });

      return
    }

    this.setState({
      showOrderLeaving: false,
    });
  };

  // 显示order留言
  showOrderLeaving = () => {
    this.setState({
      showOrderLeaving: true
    });
  };

  // order留言 多行文本框
  textAreaChange = e => {
    let flag = true;
    if (e.target.value !== this.defaultMsg) {
      flag = false;
    } else {
      flag = true;
    }

    this.setState({
      textAreaVal: e.target.value,
      generalBtnDisabled: flag
    });
  };

  // 保存留言
  generalClick = () => {
    const { updataExt, listData, index, sonIndex } = this.props;
    const { textAreaVal } = this.state;

    this.hideOrderLeaving();

    const { storeId, detail: { id } } = listData
    CommonDataCollect({
      event_type: 'subscription_analysis',
      action: 'order_save_message',
      store_id: storeId,
      sale_platform: 'shopify',
      sale_platform_product_id: id,
      action_value: textAreaVal
    })

    updataExt({
      type: 'msg',
      oid: listData.id,
      index,
      sonIndex,
      v: textAreaVal,
      id:listData.id
    });
  };

  // 展示换绑商品
  showEdit = () => {
    this.props.toggleShowEdit(this.props.sonIndex);
  };

  // mark as fulfilled
  showFulfilled = () => {
    const { child, listData, markAsFulfilled } = this.props;
    markAsFulfilled(listData.id, child.id);
  };

  // 展示mapping
  showMapping = () => {
    localStorage.setItem('mapping_dot','order')
    const { child, showMapping, listData } = this.props;

    const { storeId, detail: { id } } = listData
    //记录Mapping的数据值  保存订单的时候需要用到
    window.MAPPING_VALUE = {
      id:listData.id
    }

    CommonDataCollect({
      event_type: 'subscription_analysis',
      action: 'order_open_mapping',
      store_id: storeId,
      sale_platform: 'shopify',
      sale_platform_product_id: id
    })

    showMapping(child, listData.storeId, listData.id);
    webCustomCollect({
      action:"order_click_mapping",
      custom_info:[]
    })
  };

  // action 操作
  orderAction = e => {
    const v = e.key;

    switch (v) {
      case 'message':
        this.showOrderLeaving();
        break;
      case 'edit':
        this.showEdit();
        break;
      case 'fulfilled':
        this.showFulfilled();
        break;
      case 'bulkEdit':
        this.showMapping();
        break;
      default:
    }
  };

  // 发送ga统计
  sendGA = e => {
    const {
      target: {
        dataset: { ga }
      }
    } = e;

    if (ga) {
      reactGaEvent({
        category: 'Order',
        action: ga
      });
    }
  };

  render() {
    const {
      child = {},
      child: { AECanceled = [], platformProductId = undefined } = {},
      status, // tabStatus
      sonIndex = 0,
      useSp,
      listData,
      renderIndex,
      TMCurrentMess
    } = this.props;


    const { showOrderLeaving, textAreaVal, generalBtnDisabled } = this.state;

    /**
     * 1 pengding
     * 2 awaiting order     ----
     * 3 awaiting payment
     * 4 awaiting shipment
     * 9 await fulfillment
     * 5 fulfilled
     * 6 canceled           -----
     * 7 failed orders      -----
     */



    const arr = [2, 6, 7]; // 这几个tab才显示编辑

    const newArr = [3,4,9,5]

    let howToDealPendingOrderLink =
      'https://help.dsers.com/pending-orders-introduction-for-shopify/';

    if (getLanguage() === 'fr-FR') {
      howToDealPendingOrderLink =
        'https://help.dsers.com/pending-orders-introduction-for-shopify/';
    }

    const showIcon = () => {
      if (this.props.importFlag.newUserInfo.created_at > window.NEW_INFO_TIME && JSON.parse(localStorage.getItem('store'))?.import_flag == 0 && renderIndex==0 && (sonIndex==0||sonIndex==undefined)) {
        return true
      } else {
        return useSp.platform_product_id == '' && useSp.platform_sku == '' ? true : false
      }
    }

    const ShowI = this.props.importFlag.newUserInfo.created_at > window.NEW_INFO_TIME && JSON.parse(localStorage.getItem('store'))?.import_flag == 0 && renderIndex==0 && (sonIndex==0||sonIndex==undefined)
    return (
      <>
        {arr.includes(status) ? (
          <div
            className={orderStyles.productOverride}
            style={{ display: child.platformType > 100 ? 'none' : '' }}
          >
            {(status != 6 || AECanceled[sonIndex] === 0 || AECanceled[sonIndex] === 1 )  ? (
              // canceled tab 不显示 但如果时ae取消单要显示mapping按钮
              // 除了other以为都不展示此按钮
              <Tooltip
                placement="left"
                title={intl.get('order.order_product.mapping_tip')}
                overlayClassName={orderStyles.BtnTxt}
              >
                <div className={className(orderStyles.spanMappdingIcon,{
                  [orderStyles.spanMappdingIconAmi]:ShowI
                })} 
                  style={{display: showIcon()? 'block' : ''}} 
                  onClick={this.showMapping}>
                  <img src={require('../../../assets/newImportList/shopping_cart-24px.svg')}></img>
                </div>
              </Tooltip>
            ) : null}
            <br />
            {moreAction(this.orderAction, child, status, useSp,sonIndex, listData)}
          </div>
        ) : status == 5 ? (
          // 完成单只显示icon
          <div
            className={className(orderStyles.fulfilledIco, {
              [orderStyles.fulfilledTwoIco]: true
            })}
            style={{ display: child.platformType > 100 ? 'none' : '' }}
          >
            <Icon
              type="check-circle"
              style={{ color: '#25C655', fontSize: 16 }}
            />
          </div>
        ) : (
          <div
            className={orderStyles.fulfilledIco}
            style={{
              display: child.platformType > 100 ? 'none' : '',
              width: 45
            }}
          >
            <Icon
              type="check-circle"
              style={{ color: '#25C655', fontSize: 16 }}
            />
          </div>
        )}
        {
          newArr.includes(status) ? <span>
            <ActionsLimeTime child={child} status={status} useSp={useSp} />
          </span> : null
        }
        {/* order留言 */}
        {showOrderLeaving ? (
          <Modal
            visible={showOrderLeaving}
            onCancel={() => { this.hideOrderLeaving('close')  }}
            footer={null}
            maskClosable={false}
            title={intl.get('order.message_dialog.tm_title')}
            width={538}
            wrapClassName={orderStyles.orderLeaving}
          >
            <>
              <div className={orderStyles.generalCont}>
                <p>{intl.get('order.message_dialog.tm_desc')}</p>
                <TextArea
                  rows={4}
                  placeholder="When the message is empty, the global default message template will be applied"
                  disabled={true}
                  // placeholder={intl.get('order.message_dialog.placeholder')}
                  defaultValue={TMCurrentMess}
                  maxLength={TMCurrentMess.length}
                  onChange={this.textAreaChange}
                />
                <p className={orderStyles.generalTextLength}>
                  {this.messageMaxLength - TMCurrentMess.length * 1}/
                  {this.messageMaxLength}
                </p>
                <p className={orderStyles.generalText}>
                  <Button
                    type="primary"
                    onClick={this.generalClick}
                    disabled={true}
                    data="dx"
                  >
                    {intl.get('public.save')}
                  </Button>
                </p>
              </div>
            </>
          </Modal>
        ) : null}
      </>
    );
  }
}


const TimeLine = ({ AECanceled ,useSp, status}) => {
  if(AECanceled && status == 6){
    return <>
      <div className="syncTime">
        <p className="oneTime">{intl.get('order.sync_last_chan')}</p>
        <StartTime useSp={useSp} status={status} />
        {
          status == 6 ? null : <EndTime useSp={useSp} status={status} />
        }
      </div>
    </>
  }
  if(status == 7){
    return <>
      <div className="syncTime">
        <p className="oneTime">{intl.get('order.sync_last_chan')}</p>
        <StartTime useSp={useSp} status={status} />
      </div>
    </>
  }
  return null
}

const StartTime = ({status, useSp }) => {
  return <>
    <p className="lastTime">{intl.get('order.sync_time_last_chan')}:</p>
    <p className="lastTime">{ useSp?.time?.LastSyncTime }</p>
  </>
}

const EndTime = ({status, useSp}) => {
  if(status == 6){
    return null
  }
  if(status == 7){
    return null
  }
  return <>
    <p className="lastTime">{intl.get('order.sync_time_next_chan')}:</p>
    <p className="lastTime">{ useSp?.time?.NextSyncTime }</p>
  </>
}


// 给卖家卖家留言 手动换绑 手动fulfilled
function moreAction(orderAction, child, status,useSp,sonIndex, listData) {

  const clickHandler = () => {

    const { storeId, detail: { id } } = listData
    CommonDataCollect({
      event_type: 'subscription_analysis',
      action: 'order_action_edit',
      store_id: storeId,
      sale_platform: 'shopify',
      sale_platform_product_id: id
    })

    webCustomCollect({
      action:"click_action_edit",
      custom_info:[]
    })
  }

  return (
    <Dropdown
      trigger={['click']}
      getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
      overlay={
        <Menu onClick={orderAction}>
          <Menu.Item key="message">
            {intl.get('order.order_product.action.message')}
          </Menu.Item>
          {child.supplierMapType != 4 ? (
            <Menu.Item onClick={clickHandler} key="edit">
              {intl.get('order.order_product.action.edit')}
            </Menu.Item>
          ) : null}
          <Menu.Item key="fulfilled">
            {intl.get('order.order_product.action.mark_as_fulfilled')}
          </Menu.Item>
          {
            status == 2 || status == 7 ? null : (
              !useSp?.time ? null : <div className="syncTimebox">
              <TimeLine AECanceled={child.AECanceled[sonIndex] === 0 || child.AECanceled[sonIndex] === 1} status={status} useSp={useSp}/>
            </div>
            )
          }
        </Menu>
      }
      placement="bottomRight"
    >
      <Tooltip title={intl.get('order.order_product.action.title')}>
        <span className="moreIcons">
          <i class="material-icons notranslate">more_vert</i>
        </span>
      </Tooltip>
    </Dropdown>
  );
}
